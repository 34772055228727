<template>
    <div>
        <button 
            class="direction-btn prev-btn"
            @click='directionBtnClick(false)'
            >
            <prevBtnIcon />
        </button>
        <button 
            class="direction-btn next-btn"
            @click='directionBtnClick(true)'
            >
            <nextBtnIcon />
        </button>
    </div>
</template>

<script>
import prevBtnIcon from "@/icons/prevBtn.vue";
import nextBtnIcon from "@/icons/nextBtn.vue";
export default {
    name: 'directionButtons',
    components: {
        prevBtnIcon,
        nextBtnIcon,
    },
    created() {
        this.$root.EventBus.$on('directionBtnClick', (next) => {
            this.directionBtnClick(next);
        });
    },
    methods: {
        async directionBtnClick(next) {

            let { activeStory, allStories } = await this.$store.dispatch('getElements');

            this.seenAudit(next);

            let allStoriesArray = Array.from(allStories);
            let order = allStoriesArray.findIndex(function(story) {
                return story == activeStory;
            });
            
            let limitOrder = 0;
            if (next) limitOrder = (allStories.length - 1);
            let storyTransition = order != limitOrder ? true : false;

            if (storyTransition) {
                this.storyTransition(next);
            } else {
                this.storyBoxTransition(next);
            }
        },
        
        async seenAudit(next) {
            let { activeStory, activeStoryBox } = await this.$store.dispatch('getElements');
            let processingStoryBox, processingStory;

            if (next) {
                processingStoryBox = activeStoryBox.nextSibling;
                processingStory = activeStory.nextSibling;
            } else {
                processingStoryBox = activeStoryBox.previousSibling;
                processingStory = activeStory.previousSibling;
            }

            if (bpStory.isElement(processingStory)) {
                let activeStory = processingStory;
                this.$store.dispatch('cookies/setSeen', {activeStoryBox, activeStory});
            } else if (bpStory.isElement(processingStoryBox)) {
                let activeStoryBox = processingStoryBox;
                let activeStory = activeStoryBox.querySelector('.story-item.active');
                this.$store.dispatch('cookies/setSeen', {activeStoryBox, activeStory});
            }
        },
        async transitionProcess(process, status) {
            /**
             * process values
             * 0 = storyTransition
             * 1 = storyBoxTransition
             */
            let { nextBtn, prevBtn, video } = await this.$store.dispatch('getElements');
            if (video) video.pause();
            this.$store.dispatch('activeStory/setMediaType', video);
            if (process == 0) {
                this.disabledAutoTransition(status);
            } else if (process == 1) {
                if (status) {
                    nextBtn.setAttribute('disabled', 'disabled');
                    prevBtn.setAttribute('disabled', 'disabled');
                    this.disabledAutoTransition(status);
                } else {
                    setTimeout(() => {
                        nextBtn.removeAttribute('disabled');
                        prevBtn.removeAttribute('disabled');
                        this.disabledAutoTransition(status);
                    }, 350);
                }
            }
            if (!status) this.$store.dispatch('activeStory/setPublishTime');
        },
        async disabledAutoTransition(status) {
            if (!this.$store.getters['autoTransition/paused']) {
                this.$store.dispatch('autoTransition/disabled', status);
            }
        },
        async storyBoxTransition(next) {
            let { 
                storyBoxGeneral, 
                activeStoryBox,
                pointers
            } = await this.$store.dispatch('getElements');

            let marginLeft = bpStory.getRealVal(storyBoxGeneral, 'margin-left');
            let notActiveStoryBoxWidth = this.$store.getters['resizer/notActiveStoryBoxWidth'];
            let activeStoryBoxWidth = this.$store.getters['resizer/activeStoryBoxWidth'];

            let processingStoryBox;
            if (next) {

                if (!bpStory.isElement(activeStoryBox.nextSibling)) return;
                processingStoryBox = activeStoryBox.nextSibling;
                this.$store.dispatch('activeStory/reset');
                
            } else {

                pointers.forEach((pointer) => {
                    pointer.classList.remove('seen');
                    pointer.classList.remove('stop');
                });

                if (!bpStory.isElement(activeStoryBox.previousSibling)) return;
                processingStoryBox = activeStoryBox.previousSibling;

            }

            this.transitionProcess(1, true);

            activeStoryBox.style.width = notActiveStoryBoxWidth + 'px';
            processingStoryBox.style.width = activeStoryBoxWidth + 'px';
            activeStoryBox.classList.remove('active');
            processingStoryBox.classList.add('active');

            if (next) {
                let nextPointer = processingStoryBox.querySelector(".pointer.active");
                if (this.$store.getters['autoTransition/paused']) {
                    nextPointer.classList.add('stop');
                }
                storyBoxGeneral.style.marginLeft = (parseInt(marginLeft) - (notActiveStoryBoxWidth + 40)) + 'px';
            } else {
                let lastPointer = processingStoryBox.querySelectorAll('.pointer');
                lastPointer[lastPointer.length - 1].classList.remove('seen');
                storyBoxGeneral.style.marginLeft = (parseInt(marginLeft) + (notActiveStoryBoxWidth + 40)) + 'px';
            }

            this.transitionProcess(1, false);
        },
        async storyTransition(next) {
            let { activeStoryBox, activeStory, activePointer } = await this.$store.dispatch('getElements');
            this.transitionProcess(0, true);

            let processingStory;
            if (next) {
                processingStory = activeStory.nextSibling;
            } else {
                processingStory = activeStory.previousSibling;
            }

            let storyId = processingStory.getAttribute('data-story-id');
            let processingPointer = activeStoryBox.querySelector(".pointer[data-story-id='"+storyId+"']");

            if (this.$store.getters['autoTransition/paused']) {
                activePointer.classList.remove('stop');
                processingPointer.classList.add('stop');
            }

            activePointer.classList.remove('active');
            processingPointer.classList.add('active');
            activeStory.classList.remove('active');
            processingStory.classList.add('active');

            if (next) {
                activePointer.classList.add('seen');
            } else {
                processingPointer.classList.remove('seen');
                activePointer.classList.remove('seen');
            }

            this.transitionProcess(0, false);
        }
    }
}
</script>

<style lang="scss" scoped>
    .direction-btn {
        width: 28px;
        height: 28px;
        background: #BBBBBB;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 14px);
        transition: 150ms linear;
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 8;
        opacity: .6;
        padding: 0px;
        margin: 0px;
        &:hover {
            opacity: 1;
            transition: 150ms linear;
        }
        svg {
            top: 7px;
            position: absolute;
        }
        &.prev-btn {
            left: calc(50% - 208px);
            svg {
                left: 6px;
            }
        }
        &.next-btn {
            right: calc(50% - 208px);
            svg {
                right: 6px;
            }
        }
    }
    @media screen and (max-width: 450px) {
        .direction-btn {
            background: #fff;
            box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.2);
        }
    }
</style>